/* eslint-disable */
<template>
    <div>
        {{ subscriptions }}
        <v-container>
          
        </v-container>
    </div>
</template>

<script>

import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";
// import EditorX from "../../components/universal/EditorX.vue";
// import {
// } from "@/api/user.js";

export default {
    props:{
    
    },
    components: {
    
},
    data() {
        return {
            rules: rules,
            princings:[{
                name:"Free",
                description:"Use Orang code for personal projects",
                unit:"$",
                amount:0,
                period:"per month",
                comment:"No credit card required",
                featureDetailTitle:"Free features:",
                features:[{
                    name:"5Gib storage"
                }],
                cardClass:"white",
                btnText:"Get started",
                order:1,
            }, 
            {
                name:"Standard",
                description:"Use Orang code for personal projects",
                unit:"$",
                amount:9.99,
                period:"per month",
                comment:"Billed annually at $99.99 USD",
                featureDetailTitle:"Standard features:",
                features:[{
                    name:"5Gib storage"
                }],
                cardClass:"orange white--text",
                btnText:"Buy Orangcode Standard",


            },
            {
                name:"Advanced",
                description:"Use Orang code for personal projects",
                unit:"$",
                amount:24.99,
                period:"per month",
                featureDetailTitle:"Advanced features:",
                comment:"Billed annually at $269.99 USD",
                features:[{
                    name:"5Gib storage"
                }],
                cardClass:"blue white--text",
                btnText:"Buy Orangcode Advanced",


            }
        ]
        };
    },
    computed: {
        ...mapGetters([
            "subscriptions",
         
        ]),


    },
    methods: {
        ...mapActions([
            "getSubscriptionList",
          
        ]),
        async initialize() {
            try {
                this.loading = true;
                await this.getSubscriptionList();
            } catch (error) {
                console.log("initialize home", error);
                methods.dispatchError(this.$store);
            } finally {
                this.loading = false;
        
            }
        },
        onSuccess() {
            this.initialize();
            this.$emit("refresh");
        },
    },

    mounted() {
        this.initialize();
    },
};
</script>

<style></style>